<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>


		<div v-if="!loadingData">

            <a-row :gutter="24" class="">
                <a-col :span="24">
                    <ClassPayment v-if="transactionDetails.isClazz != null && transactionDetails.isClazz" :transactionDetails="transactionDetails" :userDetails="userDetails"/>
                </a-col>
            </a-row>
		</div>


		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
					<a-spin class="text-primary" size="large"/>
				</a-col>

			</a-row>
		</div>

	</div>
</template>

<script>
    import ClassPayment from './ClassPayment.vue';
    import QuizPayment from './QuizPayment.vue';
    import NotesPayment from './NotesPayment.vue';
    // import BookingPayment from './BookingPayment.vue';
    import VueFriendlyIframe from 'vue-friendly-iframe';

	export default ({

        components: {
            ClassPayment,
            QuizPayment,
            NotesPayment,
            // BookingPayment,
            VueFriendlyIframe,
        },
		

		data() {
			return {

				loadingData: false,

				userDetails: {},

                transactionDetails: {}
      		}
		},
		
		created() {
            this.getUserDetails();
            this.getTransactionDetails();
		},
		methods: {

            onLoad(frame) {
                this.myIframe = frame.contentWindow

                console.log(frame)
            },

            iframeLoad(frame) {
                console.log(frame)
            },


            async getUserDetails() {

				let userInfo = await localStorage.getItem("user_details")

				if(userInfo != null) {
                    this.userDetails = JSON.parse(userInfo);
				}
			},

            async getTransactionDetails() {

                

                this.loadingData = true;

                setTimeout(async() => {
                    let transactionInfo = await localStorage.getItem("transaction_details")

                    // console.log(transactionInfo)

                    if(transactionInfo != null) {
                        this.transactionDetails = JSON.parse(transactionInfo);

                        this.loadingData = false;
                    } else {
                        this.loadingData = false;
                    }
                }, 100);

				

			},
         

		},
	})

</script>

<style scoped>

</style>