<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 0px;">

			<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form mt-20">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					
                    <a-row v-if="transaction.loading" type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

                        <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                            <a-spin class="text-primary" size="large"/>
                        </a-col>

                    </a-row>
					
				</a-card>
			</a-col>

		</a-row>
		
	</div>

</template>

<script>
    import { notification } from 'ant-design-vue';

	export default ({

        props: {
			transactionDetails: {
				type: Object,
				default: () => {},
			},

            userDetails: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {

                myIframe: null,

                transaction: {
                    uuid: null,
                    amount: null,
                    isClazz: true,
                    isQuiz: false,
                    isMaterials: false,
                    isBooking: false,
                    item: null,
                    paidBy: null,
                    paidTo: null,
                    channel: null,
                    createdAt: null,
                    updatedAt: null,
                    uuid: null,
                    isCompleted: false,
                    paymentLink: null,
                    status: null,
                    loading: false,
                    hasData: false,
                }

      		}
		},
        mounted () {
			let breadcrumbs = [
				{ title: 'Classes', url: `/classes`, isActive: false },
				{ title: 'Details', url: `/classes/view/${this.transactionDetails.item}`, isActive: false },
                { title: 'Payment Processing', url: '', isActive: true }
            ];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.initializePayment();
		},
		methods: {


            initializePayment() {

                this.transaction.loading = true;

                let url = `${this.$BACKEND_URL}/payments?uuid=${this.transactionDetails.uuid}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status >= 200 && response.status < 210) {

                        this.transaction.uuid = response.data.data.uuid;
                        this.transaction.amount = response.data.data.amount;
                        this.transaction.item = response.data.data.item;
                        this.transaction.paidBy = response.data.data.paidBy;
                        this.transaction.paidTo = response.data.data.paidTo;
                        this.transaction.channel = response.data.data.channel;
                        this.transaction.createdAt = response.data.data.createdAt;
                        this.transaction.updatedAt = response.data.data.updatedAt;
                        this.transaction.uuid = response.data.data.uuid;
                        this.transaction.paymentLink = response.data.data.paymentLink;
                        this.transaction.status = response.data.data.status;
                        this.transaction.loading = false;
                        this.transaction.hasData = true;

                        if(response.data.data.status == 'COMPLETED') {

                            let url = `${this.$BACKEND_URL}/members/${response.data.data.item}`;

                            this.$AXIOS.post(url, {uuid: userDetails.uuid}).then(async(response) => {

                                this.notify('You have successfully joined the class', 'success')

                                this.transaction.loading = false;

                                this.$router.push(`/my-classes/view/${response.data.data.item}`)

                            }).catch(async(error) => {
                                
                                this.transaction.loading = false;

                                this.notify(error.response != null  && error.response.data != null ? error.response.data.message : "Connection error", 'error');

                                if(error.response && error.response.status == 401) {
                                    await localStorage.setItem("user_token", null);
                                    await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                                    this.$router.push(`/sign-in`);
                                }
                            });

                        }
                        
                    }

                    this.transaction.loading = false;

                }).catch((err) => {
                    
                    this.transaction.loading = true;
                    
                    this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                });

			},

            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },
         

		},
	})

</script>

<style scoped>

</style>