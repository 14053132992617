<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 100px;">

			<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form mt-20">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					
                    <a-row v-if="transaction.loading" type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

                        <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                            <a-spin class="text-primary" size="large"/>
                        </a-col>

                    </a-row>
					
				</a-card>
                
			</a-col>

		</a-row>
		
	</div>

</template>

<script>
    import { notification } from 'ant-design-vue';

	export default ({
        props: {
			paymentDetails: {
				type: Object,
				default: () => {},
			},

            userDetails: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {

				payment: {
                    loading: false,
                },

      		}
		},
		created() {
            this.initializePayment();
		},
		methods: {


            initializePayment() {

                this.payment.loading = true;

                let url = `${this.$BACKEND_URL}/payments/link`;

                let payload = {
                    amount: this.paymentDetails.amount,
                    isClazz: false,
                    isQuiz: true,
                    isMaterials: false,
                    isBooking: false,
                    item: this.paymentDetails.item,
                    paidBy: this.paymentDetails.paidBy,
                    paidTo: this.paymentDetails.paidTo,
                };

                console.log(payload)

                this.$AXIOS.post(url, payload).then(async(response) => {
                    if (response.status >= 200 && response.status < 210) {

                        console.log(response.data)
                        
                    }

                    this.payment.loading = false;

                }).catch((err) => {
                    
                    this.payment.loading = true;
                    
                    this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');

                });

			},


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },
         

		},
	})

</script>

<style scoped>

</style>